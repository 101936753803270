// Clone element to destination
export function cloneTo(el, target) {
	const element = document.querySelector(el);
	const elementTargetDestination = document.querySelector(target);
	const elementTargetDestinationClone = element.cloneNode(true);
	elementTargetDestination.appendChild(elementTargetDestinationClone);
}

// If custom breakpoints are set, this needs to change.
export const screens = {
	"2xl": 1536,
	'xl': 1280,
	'lg': 1024,
	'md': 768,
	'sm': 640,
};
// I know we could import the whole config and get it dynamically, but since this is all we need, I'm saving bundle size.

const getTheKey = (obj, value) => {
	return obj.keys(this).find((key) => object[key] === value);
};

const getTheValue = (obj, key) => {
	return obj.keys(this).find((value) => object[value] === key);
};

export function convertBreakpoint(valToConvert) {
	if (isNaN(valToConvert)) {
		return screens[valToConvert];
	}
	return getTheKey(screens, valToConvert)
}

export function getCurrentBreakpoint() {
	return Object.entries(screens).find((entry) => window.innerWidth > entry[1]);
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
}

// ajax portion of page into #element
export function ajaxSelection(url, element, selector) {
	fetch(url)
		.then((response) => response.text())
		.then((html) => {
			// get the content from external page
			var parser = new DOMParser();
			var doc = parser.parseFromString(html, "text/html");
			var content = doc.querySelector(selector);
			// insert the content into the element
			console.log(content)
			element.innerHTML = content.outerHTML;
		});
}
// animate elements from one classList to another
window.__FadeIn_Group = function(elems) {
	console.log("animation triggered")
	elems.forEach(function(el, i) {
		const	___from  = el.getAttribute('data-from'),
				___to    = el.getAttribute('data-to')

		___to.split(' ').forEach(function(item) {
			el.classList.remove(item)
		})
		___from.split(' ').forEach(function(item) {
			el.classList.remove(item)
		})

		const   dataFadein = el.getAttribute('data-fade-in'),
				fadeInProperties = dataFadein.split(';'),
				currentClasses = el.getAttribute('class'),
				___delay = fadeInProperties[0].split(':')[1],
				___speed = fadeInProperties[1].split(':')[1]


				___from.split(' ').forEach(function(item) {
					el.classList.add(item)
				})

		el.style.transition = `all ${___speed}ms ease-in-out ${___delay}ms`


		setTimeout(function() {
			el.className = currentClasses + ` ${___to}`
		}, 0)
	})
}
window.convertToClassname = function (string) {
	return string.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase().replace(/ /g, '-').replace('--', '-');
}
// start background animation
window.setBgAnimationPosition = function (startingPoint, animationElement, offset) {
	var topBoundingBoxOffset = document.querySelector(startingPoint).getBoundingClientRect().top,
		distanceFromTop = topBoundingBoxOffset + window.pageYOffset,
		bandAnimation = document.querySelector(animationElement),
		distanceFromTop = distanceFromTop + offset
	bandAnimation.style.top = distanceFromTop + 'px'
	//console.log(startingPoint, animationElement, offset, distanceFromTop + 'px')
}